.country-directory-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    .see-all {
        font-size: 12px;
        color: #000;
        font-weight: 600;

        a {
            font-size: 12px;
            color: #000;
            font-weight: 600;
            text-decoration: none;
        }
    }

    .country-directory-search {
        margin-bottom: 24px;
        max-width: 720px;
        position: relative;

        input {
            padding-left: 44px;
        }

        span {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            font-size: 20px;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 28px;
        color: #000;
        margin-bottom: 26px;
    }

    .tag-filter-btns-panel {
        display: flex;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        gap: 10px;
        padding-bottom: 26px;

        &::-webkit-scrollbar {
            width: 0;
        }

        .tag-filter-btn {
            background: transparent;
            border-radius: 8px;
            padding: 5px 15px;
            color: #fff;
            font-size: 14px;
            line-height: 20px;
            color: #c3c8bb;
            display: inline-block;
            border: 1px solid #424940;

            &.selected {
                background: #424940;
            }
        }
    }
}

.coutry-city-block-wrapper {
    display: flex;
    gap: 16px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &.coutry-city-block-wrapper-single{
        flex-wrap: wrap;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    .coutry-city-block {
        max-width: 217px;
        width: 100%;

        .coutry-city-block-pic {
            border-radius: 8px;
            overflow: hidden;
            width: 155px;
            height: 151px;
            margin-bottom: 12px;

            img {
                object-fit: cover;
            }
        }

        .coutry-city-block-content {
            h4 {
                font-size: 14px;
                line-height: 20px;
                color: #2f312d;
            }

            .alert-text {
                font-size: 12px;
                line-height: 16px;
                color: #5d5f5a;
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                    position: relative;
                    font-size: 20px;
                    margin-right: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #f9671c;
                    }
                }
            }
        }
    }
}