@import "../../colors.scss";
.cases-tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width:calc(100% - 20px);
    border-bottom: 1px solid #e0e4d6;
    background: #fdfdf5;
    border-radius: 0;
    justify-content: space-around;
    padding: 0 15px;
    margin: 0 10px;
    cursor: pointer;
    
    .case-tab-li {
        display: block;
        flex: 0 1 auto;
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 14px;
        position: relative;
        padding-left: 0;
        padding-right: 0;
        &.active{
            color: $primary;
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                height: 3px;
                background: $primary;
                left: 0;
                right: 0;
                display: block;
                border-radius: 20px 20px 0 0;
            }
        }
    }
}

.tab-content {
    display: none;
}

.tab-content.active {
    display: block;
}
.cases{
    max-width: 720px;
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
    padding: 24px;
    border-radius: 16px;
    // background-color: #fcfdf6;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 24px;
    .container{
        // max-width: 360px;
    }
    .case-card{
        background: #fff;
        padding: 16px 24px;
        .case-card-inner{
            justify-content: space-between;
        }
    }
}
.text-end{
    a{
        .btn{
            padding: 0 24px;
            line-height: 40px;
            border: 0;
            color:#fff;
            background: $primary;
        }
    }
}
.cases.caselist-wrapper{
    padding-top:75px !important;
    .caselist-heading{
        border-bottom: 1px solid #000;
        padding-bottom: 10px;
        .btn-success{
            min-width: 293px;
        }
    }
    .case-status{
        position: absolute;
        left: 0;
        top: 4px;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        padding: 5px 8px;
        border-radius: 0 5px 5px 0;
        &.pending{
            background: #f9671c;
        }
        h4{
            font-size: 12px;
            line-height: 14px;
            margin: 0;
            color: #fff;  
        }
        p{
            color: #fff;
            margin-bottom: 0;
            font-size: 12px;
        }
        &.resolved{
            background: #9ad778;
            color: #000;
        }
        &.cancelled{
            background: #d81e1e;
        }
    }
    .case-card{
        position: relative;
    }
    .container{
        padding-left: 0;
        padding-right: 0;
        .cases-tab.list-group{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .whithin-sla{
        background: #c9ffbe;
        color: #8181a5;
        font-size: 12px;
        padding: 8px 5px;
        border-radius: 5px;
    }
    .created-date{
        p{
            font-size: 10px;
            line-height: 12px;
            color: #2f312d;
            margin-bottom: 0;
        }
    }
}