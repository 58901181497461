@import "../../colors.scss";
.connection-card {
    // background-color: #ff6600;
    // border-radius: 0%;
    // margin-bottom: 20px;
    // box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 10px;
    .content {
        h4{
            font-size: 18px;
            line-height: 24px;
            color: #51534f;
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 0;
            font-size: 12px;
            color: #5d5f5a;
        }
    }
    
    .connection-iamge {
        // width: 100%;
        // overflow: hidden;
        border-radius: 5%;
        //margin: 10px;
        height: 71px;
        width: 100%;
        object-fit: cover;
    }
    .connection-link {
        text-decoration: none;
    }
    .content {
        margin-top: 10px;
    }
    .go-to-details {
        display: flex;
        justify-content: center;
        // height: 20%;
        align-items: center;
        padding: 10px;
    }
}
.btn-default{
    background: #fff;
}