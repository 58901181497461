@import "../../colors.scss";

.main-div {
    max-width: 720px;
    width: 100%;
    height: auto;
    // margin: 26px auto;
    background-color: $accent;
    border-radius: 16px;
    padding: 24px;
    gap: 5px;
    // border: 1px solid rgb(221, 219, 219);

    .heading {
        height: 66px;
        width: 98%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .usericon {
            width: 40px;
            height: 40px;
        }

        .heading-left {
            width: 50%;
            height: inherit;
            display: flex;
            justify-content: left;
            align-items: center;

            .title {
                display: flex;
                flex-direction: column;
                margin-left: 5px;

                p {
                    margin: 0px;
                }

                .name {
                    font-size: 16px;
                    font-weight: bold;
                }

                .location {
                    font-size: 12px;
                }
            }
        }

        .heading-right {
            width: 40%;
            height: inherit;
            display: flex;
            justify-content: right;
            align-items: center;
        }
    }

    .image-div {
        // height: 170px;
        height: 260px;
        width: inherit;

        .image {
            height: inherit;
            width: 100%;
            object-fit: cover;
        }
    }

    .description-head {
        font-size: 16px;
        line-height: 24px;
        color: #1a1c18;
        width: 180px;
    }

    .pera {
        margin-bottom: 0;
        margin-top: -1rem;
    }

    .description {
        height: auto;
        width: 100%;
        margin-top: -1rem;
        padding: 16px;
        display: flex;
        justify-content: left;

        div {
            width: 50%;
            height: inherit;

            .description-text {
                font-size: 16px;
            }

            .location {
                font-size: 14px;
            }
        }
    }

    .details {
        margin-top: -3rem;
        height: auto;
        width: 100%;
        padding: 16px;

        p {
            font-size: 14px;
        }
    }

    .button-div {
        margin-top: -3rem;
        width: 100%;
        display: flex;
        padding: 16px;
        justify-content: flex-end;
    }

}

.dashboard-container {
    .main-div {
        .dashboard-img {
            margin-bottom: 30px;
        }

        .card-title {

            text-align: right;

            img {
                width: 30px;
                fill: #005d28;
            }
        }

        p {
            &.card-text {
                font-size: 18px;
                line-height: 22px;
                font-weight: bold;
                color: #005d28;
                min-height: 44px;
                display: flex;
                align-items: flex-end;
            }
        }
    }

    .details-carousal {
        .carousel-item {
            img {
                max-height: 300px;
                object-fit: cover;
            }
        }
    }

    .city-country-scroll-view {
        max-height: calc(100vh - 200px);
        overflow: auto;
        margin-bottom: 15px;

        &:hover::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
        }

        &:hover::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
            opacity: 1;
        }

        &:hover::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #222;
        }
    }

}