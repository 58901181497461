.advisory-card {
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top:75px;
    padding-bottom: 24px;

    h2 {
        font-size: 18px;
        line-height: 24px;
        color: #1c1e20;
        margin-top: 10px;
        margin-bottom: 26px;
    }

    .subcribe-alert {
        background: #fff;
        min-height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        border-radius: 8px;

        h3 {
            font-size: 18px;
            color: #32666b;
            font-weight: 700;
            margin-bottom: 0;
        }

        .plus {
            font-size: 30px;
            color: #43483e;
            line-height: 30px;
        }
    }

    .advisory-alert-card {
        background: #fff;
        border-radius: 0 0 8px 8px;
    }

    .advisory-alert-card-pic {
        height: 181px;

        img {
            height: 181px;
            width: 100%;
            object-fit: cover;
        }
    }

    .advisory-alert-card-content {
        padding: 10px;

        h5 {
            font-size: 12px;
            color: #219cd6;
            font-weight: 700;
        }

        h4 {
            font-size: 14px;
            font-weight: 700;
            color: #201f1e;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            color: #201f1e;
        }


    }

    .advisory-alert-bottom-cards {
    
        .advisory-alert-bottom-card {
            background: #fff;
            border-radius: 8px;
            position: relative;
            display: flex;
            margin-bottom: 24px;
            h5 {
                font-size: 12px;
                color: #219cd6;
                font-weight: 700;
            }

            h4 {
                font-size: 14px;
                font-weight: 700;
                color: #201f1e;
            }

            p {
                font-size: 12px;
                line-height: 16px;
                color: #201f1e;
            }

            .advisory-alert-bottom-card-content {
                padding: 10px;
            }

            .advisory-alert-bottom-card-pic {
                width: 95px;
                position: relative;
                flex-shrink: 0;
                border-radius: 8px 0 0 8px;
                overflow: hidden;

                img {
                    width: 95px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    object-fit: contain;
                }
            }
        }

    }
}