@import "../../colors.scss";
.case-card {
    // background-color: #ff6600;
    margin: 10px;
    .case-iamge {
        width: 100%;
        overflow: hidden;
    }
    .case-link {
        text-decoration: none;
    }
    .case-card-inner{
        gap: 16px;
        h4{
            font-size: 16px;
            line-height: 24px;
            color: #2f312d;
            margin-bottom: 4px;
        }
        p{
            font-size: 14px;
            line-height: 21px;
            color: #8181a5;
        }
    }
    .case-card-pic{
        border-radius: 8px;
        overflow: hidden;
        width: 52px;
        height: 52px;
        img{
            height: 100%;
            object-fit: cover;
        }
    }
    .card-checkbox{
        input{
            border: 1px solid #c2c8bd;
            width: 36px;
            height: 36px;
            border-radius: 8px;
        }
    }
}