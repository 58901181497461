@import 'styles/style';

.wrapper-left-right-panel{
    display: flex;
    gap: 78px;
    .cases{
        padding: 0;
        margin: 0;
        flex-grow: 1;
        .tab-content{
            max-height: calc(100vh - 270px);
            overflow: auto;
            &:hover::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar
            {
                width: 5px;
                opacity: 0;
            }
            &:hover::-webkit-scrollbar
            {
                width: 5px;
                background-color: #F5F5F5;
                opacity: 1;
            }

            &:hover::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #222;
            }
        }
    }
    .left-sidebar-panel{
        max-height: calc(100vh - 64px);
        max-width: 473px;
        flex-shrink: 0;
        position: relative;
        img{
            min-height: calc(100vh - 64px);
            object-fit: cover;
        }
        h2{
            position: absolute;
            color: #fff;
            font-size: 22px;
            line-height: 28px;
            left: 20px;
            right: 20px;
            bottom: 118px;
            font-weight: 400;
        }
    }
    .privacy-card{
        border: none;
        box-shadow: none;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .main-div{
        padding-left: 0;
        padding-right: 0;
        margin-top:0;
        padding-top: 75px;
        .childdiv.flex-wrap{
            max-height: calc(100vh - 270px);
            overflow: auto;
            &:hover::-webkit-scrollbar-track{
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                border-radius: 10px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar
            {
                width: 5px;
                opacity: 0;
            }
            &:hover::-webkit-scrollbar
            {
                width: 5px;
                background-color: #F5F5F5;
                opacity: 1;
            }

            &:hover::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #222;
            }
        }
    }
    
}

.create-case-form{
    padding-top: 75px;
    h2{
        font-size: 24px;
        line-height: 28px;
        color: #000;
    }
    .file-attchment{
        height: 115px;
    }
}

.wrapper-left-right-panel{
    .connect-wrapper{
        width: 100%;
        flex-grow: 1;
        align-items: flex-start;
        padding-top: 75px;
    }
    .card-outer.signup-wrapper-outer, .card-outer.travel-wrapper-outer{
        width: 100%;
        flex-grow: 1;
        .card{
            padding: 0;
            border: none;
            box-shadow: none;
            width: 100%;
        }
    }
    .card-outer{
        min-height: calc(100vh - 64px);
    }
}
.page-heading{
    font-size: 20px;
    line-height: 28px;
}
@media screen and (min-width:981px) and (max-width: 1024px) {
    .wrapper-left-right-panel{
        .left-sidebar-panel{
           max-width:400px;
        }
    } 
}
@media screen and (min-width: 768px) and (max-width: 980px) {
    .navbar-header{
        .nav-links{
            .nav-link{
                margin-right: 8px;
                padding: 0 2px;
            }
        }
    }
    .wrapper-left-right-panel{
        gap:40px;
        .left-sidebar-panel{
           max-width:300px;
        }
    } 
    .cases.caselist-wrapper{
        .caselist-heading{
            .btn-success{
                min-width:150px;
            }
        }
    }  
    

}
@media screen and (max-width: 767px) {
    .wrapper-left-right-panel{
        gap:0;
        flex-direction: column;
        .childdiv{
            .main--div{
                max-width:100%;
                .heading{
                    .heading-left, .heading-right{
                        width:auto;
                    }
                }
            }
        }
    }
    .case-details{
        margin-top:10px;
        padding:24px 0;
    }
    .country-directory-wrapper{
        .tag-filter-btns-panel{
            .tag-filter-btn{
                min-width:100px;
            }
        }
    } 
}

.btn {
    background-color: #fff;
    color: $dirco-green;
}
.btn.btn-success, .btn.btn-primary {
    background-color: $dirco-green;
    color: #fff;
    &:hover {
        background-color: darken($dirco-green, 4);
    }
    &:active {
        color: #fff;
    }
}

.auth-mobile{
    color: white !important;
    position: relative;
    z-index: 1000;
}

.auth-mobile-legend{
    color: white !important;
    top: -10px !important;
    float: left !important;
    background: rgb(47 49 45 / 50%) !important;
    position: relative;
    z-index: 990;
}