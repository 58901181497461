@import './colors';

.login-container {
    .card {
        padding: 20px;
        max-width: 650px;
        margin: 25px auto;
        padding: 48px;
        background-color: $accent;

        &>div {
            width: 100%;

            p {
                font-size: 14px;
            }
        }

        form {
            max-width: 360px;
            margin: 0 auto;

            .form-check {
                input {
                    width: 16px;
                    height: 16px;
                }

                label {
                    margin: 0;
                    padding: 0;
                    line-height: 1;
                    font-size: 14px;
                }
            }
        }
       
    }
}

h1 {
    font-size: var(--heading1);
}

h2 {
    font-size: var(--heading2);
    font-weight: 700;
    color: #000;
    font-size: 18px;
}

h3 {
    font-size: var(--heading3);
}

.btn {
    padding: var(--btn-apdd);
    background: #e3e3dc;
    border:0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color:#356b1a;
    &:hover{
        background: #e3e3dc;
    }
    &.btn-success{
        background:#9ad778;
        color: #123800;
    }
}

.step-indicators {
    max-width: 255px;

    label {
        font-size: 14px;
        font-weight: 600;
    }

    .step .circle {
        width: 21px;
        height: 21px;
    }

    .line {
        margin: 0;
    }
}