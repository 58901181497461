// $primary: #005811;
$primary: #356b1a;
$secondary:#e3e3dc;
$danger:#cf1010;
$success:#9ad778;
$warning:#e2cc08;
$dark: #1A1A1A;
$info:#014a89;

$accent: #FCFDF6;

$grey-800:#838486;
$grey-600:#A19F9D;
$grey-400:#EDEBE9;
$grey-100:#f5f5fa;


$button-color: #9AD778;
$button-color-hover: #bbeba2;
// $body: #f5f5fa;
$body: #fcfdf6;

$dirco-green: #005811;