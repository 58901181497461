body{
    background-color: #f5f5fa;
}
.notification-wrapper, .notification-details-wrapper{
    max-width:720px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 24px;
    .notification-row{
        display: flex;
        justify-content: space-between;
        min-height: 80px;
        align-items: center;
        gap: 16px;
        position: relative;
        border: 1px solid #cac4d0;
        background: #fff;
        border-radius: 12px;
        margin-bottom: 10px;
        .notification-pic{
            position: absolute;
            width: 80px;
            top: 0;
            right: 0;
            bottom: 0;
            border-radius: 0 12px 12px 0;
            overflow: hidden;
            img{
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .notification-content{
        padding-left: 16px;
        gap: 16px;
        .notification-avatar{
            width: 40px;
            height: 40px;
            background: #356b1a;
            border-radius: 50%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
        h3{
            font-size: 16px;
            line-height: 24px;
            color: #1a1c18;
            margin-bottom: 4px;
        }
        h4{
            font-size: 14px;
            line-height: 20px;
            color: #1a1c18;
            margin-bottom: 4px;
        }
    }
    .subscribe_alert{
        h5{
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #32666b;
            padding:10px 8px;
            margin:0;
            border-radius: 8px;
            background: #fff;
            position: relative;
            span{
                width:16px;
                height:2px;
                background:#43483e;
                position: absolute;
                right:20px;
                top:calc(50% - 1px);
                &:after{
                    content:'';
                    height:16px;
                    width:2px;
                    background:#43483e;
                    position: absolute;
                    left:calc(50% - 1px);
                    top:-7px;
                }
            }

        }
        h4{
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #1c1e20;
            padding: 10px 0 15px 0;
            margin: 0;
        }
        .newshiking_ctrl{
            padding: 10px;
            margin: 0 0 20px 0;
            background: #fff;
            border-radius: 0 0 10px 10px;
            a{
                &.news_hiketxt{
                    font-size: 12px;
                    font-weight: 900;
                    color: #219cd6;
                    text-decoration: none;
                    display:flex;
                    justify-content: space-between;
                    padding:0;
                    margin:0 0 15px 0;
                    img{
                        width:20px;
                        flex-shrink: 0;
                    }
                }
            }
            h3{
                font-size: 14px;
                font-weight: 900;
                color: #201f1e;
                padding: 0;
                margin:0;
                display:flex;
                justify-content: space-between;
                padding:0;
                margin:0 0 15px 0;
                img{
                    width:20px;
                    flex-shrink: 0;
                }
            }
            p{ 
                font-size: 12px;
                font-weight: 400;
                color: #201f1e;
                padding:0;
                margin:0;
                display:flex;
                justify-content: space-between;
                img{
                    width:28px;
                    flex-shrink: 0;
                }
            }
        }
        
       
    }
    .news_hikelist{
        background: #fff;
        box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        display:flex;
        .news_hikimage{
            width:95px;
           flex-shrink: 0;
        }
        .news_hikeright{
            padding: 10px;
            margin:0;
           a{
                font-size: 12px;
                font-weight: 900;
                color: #219cd6;
                text-decoration: none;
                display:flex;
                padding:0;
                margin:0 0 15px 0;
            }
            h3{
                font-size: 14px;
                font-weight: 900;
                color: #201f1e;
                padding: 0;
                margin:0;
                display:flex;
                padding:0;
                margin:0 0 15px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
                -webkit-box-orient: vertical;
            }
            p{ 
                font-size: 12px;
                font-weight: 400;
                color: #201f1e;
                padding:0;
                margin:0;
                display:flex;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
            }
        }
    }
}
img{
    max-width:100%;
}
.notification-details-wrapper{
    min-height: 344px;
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #cac4d0;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0;
    .notification-row{
        border: none;
    }
    .notification-details-content{
        padding: 16px;
        max-width: 50%;
        h2{
            font-size: 16px;
            line-height: 24px;
            color: #1a1c18;
        }
        h4{
            font-size: 14px;
            line-height: 20px;
            color: #43483e;
            font-weight: normal;
        }
    }
    .notification-details-pic{
        height: 64px;
        position: relative;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
        }
    }
    .notification-details-content-inner{
        padding-top: 20px;
        p{
            font-size: 14px;
            line-height: 20px;
            color: #43483e;
        }
    }
    .notification-more{
        width: 60px;
        text-align: center;
        cursor: pointer;
        svg{
            font-size: 24px;
            color: #74796d;
        }
        a{
            color: #74796d;
        }
    }
    .notification-details-btn{
        padding: 16px;
        .btn-primary{
            padding: 9px 24px !important;
            font-size: 14px;
        }
    }
}