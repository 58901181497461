.modal-check-input:checked {
     height: 2em;
    background-color: #005D28;
    border-color: #005D28;
}

.btn-modal{
    background-color: #005D28;
    border-color: #005D28; 
    color: #FFFFFF;
}

.consent-modal-body{
    padding: 3rem;
}
