@import "../../colors.scss";
.connection-details {
    .connection-details-container {
        width: 720px;
        margin: 51px auto 24px;
        padding: 24px;
        border-radius: 12px;
        background-color: #fcfdf6;
    }
    .connection-details-img-block {
        margin-bottom: 48px;
    }
    .connection-details-img {
        width: auto;
    }
    .connnection-card{
        min-height: 120px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border: none;
        margin-top: 0;
        margin-bottom: 10px;
        .card-body{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0;
            .card-title{
                text-align: right;
                img{
                    height: 29px;
                }
            }
            .card-text{
                font-size: 18px;
                line-height: 24px;
                color: #424940;
                font-weight: 600;
            }
        }
    }
}