.travelcity-list {
    h2 {
        padding: 0;
        margin: 0;
        font-size: 24px;
        color: #000;
        font-weight: bold;
        text-align: left;
    }

    .travelcity_listtxt {
        padding: 15px 0 8px 20px;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        color: #2f312d;
        position: relative;
    }

    .travelcity_listtxt:after {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        left: 5px;
        top: 23px;
        border-radius: 40px;
        background: #2f312d;
    }

    .citylist_img {
        width: 96px;
        flex-shrink: 0;
        margin-right: 20px;

        img {
            border-radius: 8px;
        }
    }

    .citylist_right {
        flex-grow: 1;

        h4 {
            padding: 0 0 10px 0;
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            color: #51534f;
        }

        a {
            font-size: 12px;
            font-weight: 500;
            color: #5d5f5a;
            text-decoration: none;
            display: flex;

            img {
                height: 16px;
                margin-right: 5px;
            }
        }
    }

}

.travelcity-listinfo {
    h2 {
        padding: 10px 0 25px 0;
        margin: 0;
        font-size: 28px;
        color: #2f312d;
        font-weight: 500;
        text-align: left;
    }

    .accordion-item {
        border: 0;
        margin: 10px 0 0 0;
        background: #fff;
        border-radius: 8px;

        .accordion-header {
            border: 0;
            padding: 10px 15px;
            margin: 0;

            .accordion-button {
                background: none;
                border: 0;
                padding: 0;
                margin: 0;
                font-size: 18px;
                line-height: 24px;
                color: #2f312d;
                font-weight: 500;
                text-align: left;
                box-shadow: none;

                span {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }

        }

        .accordian_listtxt {
            padding: 0;
            margin: 0;

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    padding: 0 0 0 0;
                    margin: 0 0 5px 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: #2f312d;

                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }


    .details-carousal {
        .carousel-item {
            img {
                max-height: 300px;
                object-fit: cover;
            }
        }
    }

    .city-country-scroll-view {
        max-height: calc(100vh - 200px);
        overflow: auto;
        margin-bottom: 15px;

        &:hover::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
            width: 5px;
            opacity: 0;
        }

        &:hover::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
            opacity: 1;
        }

        &:hover::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            background-color: #222;
        }
    }
}