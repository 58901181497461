@import './colors';

.dashboard{
    .main-div{
        padding-top: 100px;
        .col-sm-3{
            padding-left: 3px;
            padding-right: 3px;
            .card{
                box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                border:1px solid #efefef;
                .card-body{
                    padding: 10px;
                    p{
                        &.card-text{
                            min-height:55px;
                        }
                    }
                }
            }
        }
    }
}