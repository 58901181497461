@import './colors';
.welcome-card{
    .logo{
        max-width: 93px;
        flex-shrink: 0;
    }
    h1{
        color: $primary;
        font-size: var(--heading1);
    }
    h2{
        color: $primary;
        font-size: var(--heading1);
    }
    h3{
        color: $primary;
        font-size: var(--heading2);
    }
    p{
        font-size: var(--paratext);
        color: #000;   
    }
    .login-btns{
        .btn{
            border-radius: 50px;
            font-weight: 500;
        }
    }
}