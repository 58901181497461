.form-switch .form-check-input {
    width: 3.5em;
    height: 2em;
}

.form-check-input:checked {
    background-color: #43483e;
    border-color: #43483e;
}

fieldset.input {
    border: 1px solid #2f312d;
    margin-bottom: 10px;
    border-radius: 5px;
    &.invalid {
        border: 2px solid $danger;
        // padding-right: calc(1.5em + 0.75rem);
        // background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23cf1010%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23cf1010%27 stroke=%27none%27/%3e%3c/svg%3e');
        // background-repeat: no-repeat;
        // background-position: right calc(0.375em + 0.1875rem) center;
        // background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    legend {
        font-size: 12px;
        float: none;
        width: auto;
        padding: 0 10px;
        margin: 0 10px;
        border-radius: 5px;
        position: relative;
    }

    input, select {
        min-height: 43px !important;
        padding: 8px 20px;
        border: solid 1px transparent;
        width: 100%;
        background: transparent;
        border-radius: 8px;
        margin-top: -8px;

        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }
    }

    textarea {
        min-height: 106px;
        padding: 8px 20px;
        border: solid 1px transparent;
        width: 100%;
        background: transparent;
        resize: none;
        border-radius: 8px;
        margin-top: -8px;

        &:focus {
            border: none;
            box-shadow: none;
            outline: none;
        }
    }

    .select-box {
        margin-top: -9px;
        .css-1s2u09g-control, .css-1pahdxg-control {
            background-color: transparent;
            border: 0;
            box-shadow: none;
            >div{
                height: 50px;
            }
        }
    }
    &.filled{
        border: 2px solid #356b1a;
        legend{
            color: #356b1a;
        }
        input, textarea{
            color: #356b1a;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $accent inset !important;
}
.file-attchment{
    width: 100%;
    height: 92px;
    flex-grow: 0;
    padding: 12px 147px 20px 16px;
    border-radius: 4px;
    border: solid 1px #000;
    background-color: #fff;
    position: relative;
    z-index: 0;
    input{
        width: 100%;
        height: 92px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
    }
    label{
        font-size: 12px;
        color: #8181a5;
        cursor: pointer;
    }
    h6{
        font-size: 14px;
        color: #8181a5;
        padding: 10px 0 0 30px;
        cursor: pointer;
    }
}