@import "../../colors.scss";
.no-cases {
    // background-color: $primary;
    // .text2 {
    //     font-size: 16px;
    //     font-weight: bold;
    // }
    .container{
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 117px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img{
            margin-bottom: 45px;
        }
        h1{
            font-size: 20px;
            line-height: 30px;
            color: #1c1d21;
            margin-bottom: 5px;
        }
        h3{
            font-size: 14px;
            line-height: 21px;
            color: #8181a5;
            font-weight: normal;
            margin-bottom: 25px;
        }
        // button{
        //     background: #9ad778;
        //     color: #123800;
        //     font-weight: 600;
        //     border-color: #9ad778;
        //     &:hover{
        //         background: #356b1a;
        //         color: #fff;
        //     }
        // }
    }
}