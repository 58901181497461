.card-outer{
    // background: $grey-100;
    background: #fcfdf6;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .card{
        max-width: 626px;
        width: 100%;
        min-height: 647px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 128px;
        border-radius: 16px;
        box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
        background-color: #fcfdf6;
    }
}
