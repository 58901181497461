:root{
    --btn-apdd: 8px 35px
}
.btn-transparent{
    background: transparent;
    color: var($grey-100);
    border: none;
    border-radius: 50px;
}
.btn{
    border-radius: 50px;
}