@import "../../colors";

.step-indicators {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    max-width: 600px;
    margin: 12px auto;
    .step {
        position: relative;        
        .circle {
            width: 24px;
            height: 24px;
            background-color: $dark;
            border-radius: 50%;
        }
        label {
            position: absolute;
            left: 50%;
            white-space: nowrap;
            transform: translate(-50%, -50%);
            bottom: -40px;
            padding: 0;
            margin: 0;
        }
        &.active {
            .circle {
                background-color: $primary;
            }
        }
    }
    .line{
        height: 2px;
        flex-grow: 1;
        margin: 0 12px;
        background-color: $dark;
    }
}
