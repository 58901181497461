.connect-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 64px);
    .card{
        width: 100%;
        max-width: 720px;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        padding: 24px;
        border-radius: 16px;
        background-color: #fcfdf6;
        border: none;
        h3{
            font-size: 24px;
            line-height: 27px;
            font-weight: 700;
            color: #2f312d;
            margin-bottom: 32px;
        }
        .connect-fld{
            max-width: 436px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-left: auto;
            margin-right: auto;
            p{
                margin-bottom: 0;
            }
            input{
                width: 100%;
                border: none;
                background: #fcfdf6;
                padding: 5px 15px;
                &:focus{
                    border: none;
                    box-shadow: none;
                    outline: none;
                }
            }
            fieldset{
                min-height: 56px;
                border-radius: 4px;
                border: solid 1px #43483e;
                width: 100%;
                padding: 2px;
                legend{
                    font-size: 12px;
                    float: none;
                    padding: 0 5px;
                    margin-left: 10px;
                    width: auto;
                }
            }
        }
    }
}

.connect-modal{
    text-align: center;
    font-size: 12px;
    color: #2f312d;
    font-weight: 600;
    .btn-dark{
        border: solid 1px #000;
        background: #fff;
        color: #000;
        font-weight: 600;
    }
    .btn-success{
        font-weight: 600;
    }
}