@import '../colors';

.navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    position: relative;
    // position: sticky;
    // z-index: 999;
    // top: 0;
    background-color: $accent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    

    &.back-mode {
        background-color: white;
    }

    .icon {
        width: 48px;
        height: 48px;
        margin: 8px;
        padding: 12px;
        display: block;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .back {
        color: #43483e;
    }

    p.label {
        padding: 0;
        margin: 0;
        font-size: 22px;
        color: 28px;
        color: #1a1c18;
    }

    .logo {
        display: flex;
        height: 64px;
        padding: 4px;
        align-items: center;
        text-decoration: none !important;
        margin-left: 12px;

        img {
            height: 100%;
            margin-right: 12px;
        }

        p {
            margin: 0;
            text-decoration: none;
            line-height: 1.2;
        }
    }

    .nav-links {
        display: flex;
        align-items: center;

        .nav-link {
            display: flex;
            align-items: center;
            margin-right: 12px;
            padding: 0 12px;
            &.active{
                svg{
                    stroke:  $primary;
                    color: $primary;
                    path{
                        stroke:  $primary;
                       
                    }
                }
            }

            p {
                margin: 0 0 0 6px;
            }

            svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    .btn-toggle {
        background: none !important;
        border: none !important;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 12px;
        box-shadow: none;
        outline: none;

        p {
            color: rgba(33, 37, 41);
            margin: 0 0 0 6px;
        }

        svg {
            height: 20px;
            width: 20px;
            fill: rgba(33, 37, 41);
        }

        &::after {
            display: none;
        }

        &:active {
            box-shadow: none;
            outline: none;
        }

        &.btn-toggle-svg {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}

