@import "../../colors.scss";
.case-details {
    max-width: 720px;
    width: 100%;
    min-height: 608px;
    margin: 51px auto 24px;
    padding: 24px;
    border-radius: 16px;
    background-color: #fcfdf6;
    .case-details-inner{
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
    }
    .case-title h3 {
        font-size: 16px;
    }
    .case-title h4 {
        font-size: 14px;
        font-weight: normal;
    }
    .case-avatar {
        text-align: left;
    }
    .case-avatar img {
        width: 100%;
        border-radius: 50%;
        height: 40px;
    }
    .case-info {
        width: 100%;
        background-color: #e2e3dd;
        padding: 10px 36px;
        font-size: 12px;
        line-height: 16px;
    }
    .card{
        padding: 0;
        background: #fff;
        .card-img-top{
            border-radius: 0;
            height: 148px;
            width: 100%;
            object-fit: cover;
        }
        .card-text{
            margin-top: 24px;
            margin-bottom: 32px;
        }
        .case-avatar{
            width: 40px;
            height: 40px;
            margin: 4px 16px 4px 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background: #356b1a;
            border-radius: 50%;
        }
        .card-title{
            font-weight: 600;
        }
        .more-btn{
            cursor: pointer;
        }
    }
}