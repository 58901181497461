.connected-wrapper{
    max-width: 720px;
    width: 100%;
    margin: 51px auto 24px;
    padding: 24px;
    border-radius: 16px;
    background-color: #fcfdf6;
    h4{
        font-size: 24px;
        line-height: 27px;
        color: #2f312d;
        font-weight: 700;
        margin-bottom: 32px;
    }
    .cases{
        margin: 0;
        padding: 0;
        .container{
            max-width: none;
            padding: 0;
        }
        .connection-card{
            box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            padding: 10px;
            border-radius: 12px;
        }
        .connection-iamge{
            height: 71px;
            width: 100%;
            object-fit: cover;
            margin: 0;
        }
        h4{
            font-size: 18px;
            line-height: 24px;
            color: #51534f;
            margin-bottom: 5px;
        }
        p{
            margin-bottom: 0;
            font-size: 12px;
            color: #5d5f5a;
        }
    }
    .go-to-details{
        justify-content: flex-end;
    }
}