.main-div {
    width: 772px;
    margin: auto;

    .childdiv {
        display: flex;
        width: 100%;
        // align-items: center;
        // justify-content: space-evenly;
        gap: 5px;
        .single-travel{
            flex-grow: 1;
            margin-bottom: 40px;
        }
    }
    .small-elements {
        display: flex;
        justify-content: space-around;
    }
}
.main-box{
    width: 100%;
    background: #fff;
    padding: 8px;
    border: none;
    .image--div{
        width: 56px;
        height: 56px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}