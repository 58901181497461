body{
    background-color: #f5f5fa;
}
.profile-container{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 34px;
    padding-bottom: 24px;
    .profile-block-header{
        background: #fff;
        border-radius: 4px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
    }
    .profile-block-header{
        svg{
            font-size: 24px;
        }
    }
    h6{
        font-size: 12px;
        color: #1a1c18;
        margin-bottom: 4px;
    }
    h3{
        font-size: 16px;
        line-height: 24px;
        color: #1a1c18;
        margin-bottom: 4px;
    }
    h4{
        font-size: 14px;
        line-height: 20px;
        color: #43483e;
        margin-bottom: 0;
    }
    .arrow{
        svg{
            font-size: 20px;
        }
    }
    .profile-block-body-content{
        background: #fff;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 4px;
        p{
            font-size: 14px;
            line-height: 21px;
            color: #000;
            text-align: center;
        }
    }
    .qr-code{
        display: flex;
        justify-content: center;
    }
}