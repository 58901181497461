@import "../../colors.scss";
.main--div {
    max-width: 330px;
    height: auto;
    // margin: 26px auto;
    background-color: $accent;
    border-radius: 16px;
    border: 1px solid rgb(214, 213, 213);
    flex-grow: 1;
    margin-bottom: 15px;

    .heading {
        height: 66px;
        width: 98%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .usericon {
            width: 40px;
            height: 40px;
        }
        .heading-left {
            width: 50%;
            height: inherit;
            display: flex;
            justify-content: left;
            align-items: center;
            .title {
                display: flex;
                flex-direction: column;
                margin-left: 5px;
                p {
                    margin: 0px;                    
                }
                .name {
                        font-size: 16px;
                        font-weight: bold;
                }
                .location {
                    font-size: 12px;
                }
            }
        }

        .heading-right {
            width: 40%;
            height: inherit;
            display: flex;
            justify-content: right;
            align-items: center;
        }
    }

    .image-div {
        height: 250px;
        width: inherit;
        .image {
            height: inherit;
            width: 100%;
        }
    }

    .description {
        height: auto;
        width: 100%;
        margin-top: -1.5rem;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        div {
            width: 90%;
            height: inherit;
            .description-text {
                font-size: 16px;
                font-weight: bold;
            }
            .location {
                font-size: 14px;
                margin-top: -1rem;
            }
            .pera{
                font-size: 14px;
            }
        }
    }

    // .details {
    //     margin-top: -3rem;
    //     height: auto;
    //     width: 100%;
    //     padding: 16px;
    //     p{
    //         font-size: 14px;
    //     }
    // }
}
