
@import 'styles/colors';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "dark": $dark,
    "info": $info,
    "danger": $danger,
    "success": $success,
    "warning": $warning,
);

@import "~bootstrap/scss/bootstrap";