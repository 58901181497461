.app-loader-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99999999;
}

.app-loader-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #D3CCE3;
    background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);
    background: linear-gradient(to right, #E9E4F0, #D3CCE3);
    padding: 15px;
    border-radius: 15px;
}

.app-loader-message {
    font-size: 24px;
    color: #2e2e2e;
}

.loader {
    z-index: 9999999;
    background-color: rgba(0,0,0,0.9);
}