
.card-outer.signup-wrapper-outer{
    .card{
        padding: 20px;
        max-width: 720px;
        & > div{
            width: 100%;
        }
    }
    h1{
        font-size: var(--heading1);
    }
    h2{
        font-size: var(--heading2);
        font-weight: 700;
        color: #2f312d;
    }
    h3{
        font-size: var(--heading3);
    }
    .btn{
        padding: var(--btn-apdd);
    }
    .step-indicators{
        max-width: 255px;
        label{
            font-size: 14px;
            font-weight: 600;
        }
        .step .circle{
            width: 21px;
            height: 21px;
        }
        .line{
            margin: 0;
        }
    }
}
.toggle-btns{
    label{
        font-weight: 600;
    }
}