@import './colors';

.dial-code-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3px;

    span {
        padding-left: 4px;
    }
}